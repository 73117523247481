import { createContext, useEffect, useState} from 'react'
import useAppwrite from '../appwrite/useAppwrite';
import { getAllProjects } from '../appwrite/appwrite';

const UserContext = createContext();

export const UserProvider = (props) => {
    const { data:projects, refetch } = useAppwrite(() => getAllProjects());

    return (
        <UserContext.Provider value={{projects}}>
        {props.children}
        </UserContext.Provider>
    )
}

export default UserContext;