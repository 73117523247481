import { Outlet } from "react-router-dom";

const Main = () => {
    return (
        <main className="overflow-y-auto">
            <Outlet />
        </main>
    )
}

export default Main;