import './App.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from './components/universal/Header';
import NotFound from './components/errors/NotFound';
import Error from './components/errors/Error';
import Home from './components/main/Home';
import Footer from './components/universal/Footer';
import Main from './components/universal/Main';

function App() {
  return (
    <div className='d-flex flex-column vh-100'>
      <Routes>
        <Route path='/'  element={<><Header/><Main /><Footer/></>}>
          <Route index  element={<Navigate to={"/Home"}/>}/>
          <Route path='/Home' element={<Home />} />
          <Route path='*' element={<NotFound />}/>
          <Route path='/Error' element={<Error />}/>
        </Route>
      </Routes>
    </div>
  )
}

export default App;
