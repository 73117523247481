import { useEffect, useState } from "react";
import Project from './Project'
import icons from "../../../assets/icons";

const Projects = ({projects}) => {
    const [currentProject, setCurrentProject] = useState(projects[0])
    useEffect(()=>{
        setCurrentProject(projects[0])
    }, [projects])
    return (
        <div className="w-100 h-100 mt-lg-4 mb-auto">
            <span className="d-flex mb-3 mb-xl-5 border-top border-2 border-md-0 pt-4 mt-4 mt-md-2 mb-md-2 mb-lg-4 mb-xl-5 pt-md-1 justify-content-center">
                <h3 className="d-flex align-items-center  fs-lg-heading fs-xl-heading" style={{width: 'fit-content'}}><p className="me-3 me-xl-4 p-2 px-3  headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>PROJECTS <p className="p-2 fs-lg-heading fs-xl-heading  headingDecorationOne fs-4 fs-md-6 rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            </span>
            <div className="w-100 pt-xl-4">
                <Project title={currentProject?.title} stack={currentProject?.stack} type={currentProject?.type} src={currentProject?.src[0]} url={currentProject?.url} logo={currentProject?.logo}/>
            </div>
            <nav className='d-flex align-items-center justify-content-center flex-column flex-md-row mt-2 pt-4 pt-md-0 mt-md-1 mt-lg-3 mt-xl-5  mb-4 mb-0-md ms-xl-5 '>
                <div className="d-flex align-items-center w-55 justify-content-center justify-content-md-end border-md-end w-25 border-bottom border-md-bottom pe-md-4 me-md-4 pb-3 pb-md-0 mb-3 mb-md-0 pe-xl-5 me-xl-5 fa-bounce delay-1"><img src={icons.SelectProjectsIcon} alt="" srcset="" className="me-2 projectNavIcon" style={{width: "2.5rem"}}/><h4 className="fira-sans-thin-italic-bold mb-0">Select Project</h4></div>
                <ul className='d-flex align-items-center flex-wrap justify-content-evenly justify-content-md-start w-75'>
                    {projects.map( project => (
                        <button key={project.$id} className={`${currentProject?.title === project?.title ? 'onFocus':null} border p-1 rounded justify-content-center bg-white m-1 mx-xl-2 mb-2 btn-sm btn-md btn-lg btn-xl fa-flip`} onClick={() => setCurrentProject(project)}><img src={project.logo} alt="" className="w-100" /></button>
                    ))}
                </ul>
            </nav>
        </div>
    )
}

export default Projects;