import IndexStyle from '../assets/Brackets-Image.png'
import DevGreeting from '../assets/Index-Introduction.png'
import Profile from '../assets/Profile.jpg'
import IntroMessage from '../assets/Index-Intro-Name.png'
import Bracket from '../assets/Bracket.png'
import AboutBanner from '../assets/About-Banner.png'
import FaceBookIcon from '../assets/square-facebook-brands-solid.png'
import TwitterIcon from '../assets/square-twitter-brands-solid.png'
import SocialsIcon from '../assets/Socials-Icon.png'
import LinkedInIcon from '../assets/LinkedIn-Button.png'
import ResumeIcon from '../assets/Resume-Button.png'
import Javascript from '../assets/Javascript.png'
import ReactJs from '../assets/React.png'
import CSS from '../assets/CSS.png'
import VueJS from '../assets/Resume-Button.png'
import HTML5 from '../assets/HTML5.png'
import Node from '../assets/Node.png'
import ReactNative from '../assets/ReactNative.png'
import TailWindCSS from '../assets/TailWindCSS.png'
import Expo from '../assets/Expo.png'
import Appwrite from '../assets/Appwrite.png'
import Bootstrap from '../assets/Bootstrap.png'
import npm from '../assets/Npm.png'
import SequelizeJs from '../assets/SequelizeJs.png'
import ExpressJs from '../assets/ExpressJs.png'
import SelectProjectsIcon from '../assets/SelectProjectsIcon.png'
import logo from '../assets/Logo.png'

export default {
    IndexStyle,
    DevGreeting,
    Profile,
    IntroMessage,
    SelectProjectsIcon,
    Bracket,
    AboutBanner,
    FaceBookIcon,
    TwitterIcon,
    SocialsIcon,
    LinkedInIcon,
    ResumeIcon,
    Javascript,
    ReactJs,
    CSS,
    VueJS,
    HTML5,
    Node,
    Bootstrap,
    ReactNative,
    TailWindCSS,
    Appwrite,
    Expo,
    npm,
    SequelizeJs,
    ExpressJs,
    logo
}